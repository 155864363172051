<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="90vw">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="setCurrentOptions()"
          dark
          color="orange"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-square-edit-outline</v-icon>
          EDIT
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ clientName }} - {{ clientJobCodePresentable }}
        </v-card-title>

        <v-card-text v-if="!unassignedPersonManagerView">
          <br />
          <v-row>
            <v-col cols="4">
              Domains
              <v-autocomplete
                v-model="newDomain"
                :items="unusedDomains"
                label="select unused domain"
                dense
                outlined
                :disabled="updatingLoading"
              >
                <template v-slot:append-outer>
                  <v-btn
                    class="mt-n2"
                    outlined
                    icon
                    color="primary"
                    @click="addDomain()"
                    :disabled="updatingLoading"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-autocomplete>

              <EditDataTable
                :headers="domainHeaders"
                :tableData="domains"
                :storeKey="'domains'"
                :endpoint="'domain'"
                uniqueIdentifier="domain"
                :disabled="updatingLoading"
              ></EditDataTable>
            </v-col>
            <v-col cols="4">
              People
              <v-text-field
                v-model="newPerson.name"
                label="Name"
                outlined
                dense
                :disabled="updatingLoading"
              ></v-text-field>
              <v-text-field
                v-model="newPerson.email"
                label="Email"
                outlined
                dense
                :disabled="updatingLoading"
              ></v-text-field>
              <v-select
                v-model="newPerson.type"
                :items="contactTypes"
                label="select contact type"
                dense
                outlined
                :disabled="updatingLoading"
              >
                <template v-slot:append-outer>
                  <v-btn
                    class="mt-n2"
                    outlined
                    icon
                    color="primary"
                    @click="addPerson()"
                    :disabled="updatingLoading"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-select>
              <EditDataTable
                :headers="peopleHeaders"
                :tableData="people"
                :storeKey="'people'"
                :endpoint="'person'"
                uniqueIdentifier="email"
                :disabled="updatingLoading"
              ></EditDataTable>
            </v-col>
            <v-col cols="4">
              <div>
                Billing
                <v-select
                  v-model="newBillingMonth"
                  :items="billingMonths"
                  @change="updateBillingMonth()"
                  label="select billing month"
                  dense
                  outlined
                  :disabled="updatingLoading"
                >
                </v-select>
              </div>
              <div>
                Status
                <v-select
                  v-model="inactiveFlag"
                  :items="flagOptions"
                  @change="updateInactiveFlag()"
                  label="Is the client still active?"
                  dense
                  outlined
                  :disabled="updatingLoading"
                >
                </v-select>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="unassignedPersonManagerView">
          <v-container fluid>
            <v-row>
              <v-col cols="6"
                ><UnassignedPersonViewer
                  :showReturnButton="false"
                  :customStyle="'height: 75vh; overflow: auto'"
                  :clientPreselection="clientSelectionObject"
                />
              </v-col>
              <v-col cols="6">
                <EditDataTable
                  :headers="peopleHeaders"
                  :tableData="peopleOfUserType"
                  :storeKey="'people'"
                  :endpoint="'person'"
                  uniqueIdentifier="email"
                  :disabled="updatingLoading"
                ></EditDataTable
              ></v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" dark @click="closeDialog()"> Cancel </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!unassignedPersonManagerView"
            color="red"
            dark
            @click="toggleUnassignedPersonManager()"
          >
            Unassigned person manager </v-btn
          ><v-btn
            v-if="unassignedPersonManagerView"
            color="red"
            dark
            @click="toggleUnassignedPersonManager()"
          >
            Back to edit client details
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="updatingLoading">
      {{ loadingText }}
    </v-snackbar>
  </div>
</template>

<script>
import msalAuthHandler from "vue-msal-wrapper";
import EditDataTable from "./EditDataTable";
import UnassignedPersonViewer from "../components/UnassignedPersonViewer";

export default {
  props: ["client"],
  components: {
    EditDataTable,
    UnassignedPersonViewer,
  },
  data() {
    return {
      dialog: false,
      domainHeaders: [
        { text: "Domain", value: "domain" },
        { text: "", value: "actions", sortable: false },
      ],
      peopleHeaders: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Type", value: "type" },
        { text: "", value: "actions", sortable: false },
      ],
      billingMonths: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      unusedDomains: [],
      newDomain: "",
      newPerson: { clientId: 0, email: "", name: "", type: "" },
      newBillingMonth: "",
      inactiveFlag: 0,
      flagOptions: ["Yes", "No"],

      saveFlag: false,
      contactTypes: [],
      // people: [],
      updatingLoading: false,
      loadingText: "Updating client details...",

      unassignedPersonManagerView: false,
    };
  },
  mounted() {
    console.log(this.client)
    this.getDomains();
    this.newBillingMonth = this.$store.state.activeClient.renewalMonth;
    this.inactiveFlag = null;
    if (this.$store.state.activeClient.inactiveFlag === false) {
      this.inactiveFlag = "Yes";
    }
    if (this.$store.state.activeClient.inactiveFlag === true) {
      this.inactiveFlag = "No";
    }

    // this.people = this.$store.state.activeClient.people;

    msalAuthHandler
      .authenticatedApi(this.$store.state.apiUrl, "backend")
      .get(`personTypes`, {})
      .then((response) => {
        this.contactTypes = response.data.data;
      });
  },
  methods: {
    //toggle function for unassigned person manager
    toggleUnassignedPersonManager() {
      this.unassignedPersonManagerView = !this.unassignedPersonManagerView;
    },
    getMonthForDisplay(month) {
      // lowercase the month string and make the first character uppercase
      return month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
    },
    getMonthForRequest(month) {
      // uppercase the month string
      return month.toUpperCase();
    },
    setCurrentOptions() {
      this.inactiveFlag = null;
      if (!this.$store.state.activeClient.inactiveFlag) {
        this.inactiveFlag = "Yes";
      } else {
        this.inactiveFlag = "No";
      }
      this.newBillingMonth = this.getMonthForDisplay(
        this.$store.state.activeClient.renewalMonth
      );
    },
    clearInputs() {
      this.inactiveFlag = "";
      this.newDomain = "";
      this.newBillingMonth = "";
      this.newPerson = { clientId: 0, email: "", name: "", type: "" };
    },
    updateBillingMonth() {
      // this.client.renewalMonth = this.newBillingMonth;
      this.updatingLoading = true;
      if (this.inactiveFlag === "Yes") {
        this.$store.state.activeClient.inactiveFlag = false;
      } else {
        this.$store.state.activeClient.inactiveFlag = true;
      }

      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .patch(
          `clients/${this.$store.state.activeClient.id}`,
          JSON.stringify({
            billingMonth: this.getMonthForRequest(this.newBillingMonth),
            inactiveFlag: this.$store.state.activeClient.inactiveFlag,
          })
        )
        .then((response) => {
          this.updatingLoading = false;
        });
    },
    updateInactiveFlag() {
      this.updatingLoading = true;
      if (this.inactiveFlag === "Yes") {
        this.$store.state.activeClient.inactiveFlag = false;
      } else {
        this.$store.state.activeClient.inactiveFlag = true;
      }
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .patch(
          `clients/${this.$store.state.activeClient.id}`,
          JSON.stringify({
            billingMonth: this.getMonthForRequest(this.newBillingMonth),
            inactiveFlag: this.$store.state.activeClient.inactiveFlag,
          })
        )
        .then((response) => {
          this.updatingLoading = false;
        });
    },
    closeDialog() {
      this.dialog = false;
      this.clearInputs();
    },
    getDomains() {
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .get(`unusedDomains`, {})
        .then((response) => {
          this.unusedDomains = response.data.data;
        });
    },
    addDomain() {
      this.updatingLoading = true;
      this.newDomainObject = {
        clientId: this.$store.state.activeClient.id,
        domain: this.newDomain,
      };
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .post(`domain`, JSON.stringify(this.newDomainObject))
        .then((response) => {
          this.$store.state.activeClient.domains = response.data.data.domain;
          this.newDomain = "";
          this.updatingLoading = false;
        });
    },
    addPerson() {
      this.updatingLoading = true;
      this.newPerson.clientId = this.$store.state.activeClient.id;
      msalAuthHandler
        .authenticatedApi(this.$store.state.apiUrl, "backend")
        .post(`person`, JSON.stringify(this.newPerson))
        .then((response) => {
          this.$store.state.activeClient.people = response.data.data.person;
          this.newPerson = { clientId: 0, email: "", name: "", type: "" };
          this.updatingLoading = false;
        });
    },
  },
  computed: {
    storeTest() {
      return this.$store.state.test;
    },
    clientName() {
      return this.$store.state.activeClient.name;
    },
    clientJobCode() {
      return this.client.jobcodes;
    },
    clientJobCodePresentable() {
      // return a string with each item in this.clientJobCode separated by , 
      return this.clientJobCode.join(", ")
    },
    domains() {
      return this.$store.state.activeClient.domains;
    },
    clientSelectionObject() {
      return { clientId: this.client.id, title: `${this.client.jobcodes.join(",")} - ${this.client.name} | ${this.client.domains}` };
    },
    people() {
      return this.$store.state.activeClient.people;
    },
    peopleOfUserType() {
      if (this.$store.state.activeClient) {
        if (this.$store.state.activeClient.people) {
          return this.$store.state.activeClient.people.filter(
            (person) => person.type === "USER"
          );
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
  },
};
</script>
