<template>
  <v-card>
    <v-simple-table dense v-if="usageData.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">MS Username</th>
            <th class="text-left">Username</th>
            <th class="text-left">Domain(s)</th>
            <th class="text-left">Function count</th>
            <th class="text-left">Tag count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in usageData" :key="user.UserName">
            <td>{{ user.MsUserName }}</td>
            <td>{{ user.UserName }}</td>
            <td>{{ user.Domain }}</td>
            <td>{{ user.FunctionCount }}</td>
            <td>{{ user.TagCount }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div v-else>
      <v-alert type="info">No data available</v-alert>
    </div>
  </v-card>
</template>

<script>
export default {
  props: ["clientUsageData"],
  data: () => ({
    dialogHeading: "",
  }),
  mounted() {},
  methods: {
    fetchYearData(item) {
      if (item === "all") {
        this.$store.state.dataYear = item;
      } else {
        this.$store.state.dataYear = item;
      }
    },
  },
  computed: {
    usageData() {
      return this.clientUsageData.userData;
    },
  },
};
</script>
